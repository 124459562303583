import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_clients,
    clear_status_client,
    get_all_clients,
    UPDATE_CLIENTS_SUCCESS,
} from "../../../redux/client";
import {Switch, Space} from "antd";
import Select from "../../../components/select";
import Footer from "../../../components/footer";

const UpdateClient = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const {_update_clients, _get_all_clients} = useSelector((state) => state.client_module);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        codigo: "",
        nombre: "",
        zona_id: 0,
        zona: "",
        sociedad_id: 0,
        sociedad: "",
        activo: ""
    });

    useEffect(() => {
        if (_get_all_clients) {
            if (_get_all_clients.status === 200) {
                if (JSON.stringify(_get_all_clients.data) !== "{}") {
                    // eslint-disable-next-line
                    _get_all_clients.data.datos.map((item) => {
                        if (item.id === parseInt(id)) {
                            setForm({
                                ...form,
                                codigo: item.codigo,
                                nombre: item.nombre,
                                zona_id: item.zona.id,
                                zona: item.zona.nombre,
                                sociedad_id: item.sociedad.id,
                                sociedad: item.sociedad.nombre,
                                activo: item.activo
                            });
                        }
                    });
                }
            }
        }
    }, [_get_all_clients, id, form]);

    useEffect(() => {
        if (_update_clients) {
            if (_update_clients.status === 200) {
                toast.success("El cliente ha sido actualizada correctamente");
                dispatch(clear_status_client(UPDATE_CLIENTS_SUCCESS));
                history.push("/clientes");
                dispatch(get_all_clients(token))
            } else if (_update_clients.status !== 0) {
                if (_update_clients.message) {
                    toast.error(_update_clients.message);
                    dispatch(clear_status_client(UPDATE_CLIENTS_SUCCESS));
                }
            }
        }
    }, [_update_clients, dispatch, history, token]);

    const [zones, setZones] = useState([]);
    const [societies, setSocieties] = useState([]);

    const _zones = useSelector(
        (state) => state.zone_module._get_all_zones
    );

    useEffect(() => {
        if (_zones.data.datos) {
            let value_zones = _zones.data.datos.filter(x => x.activo === true)
            setZones(value_zones);
        }
    }, [_zones]);

    const _societies = useSelector(
        (state) => state.society_module._get_all_societies
    );

    useEffect(() => {
        if (_societies.data.datos) {
            let value_societies = _societies.data.datos.filter(x => x.activo === true)
            setSocieties(value_societies);
        }
    }, [_societies]);

    const handleChange = (e) => {
        if (e.target.name === "zona_id" || e.target.name === "sociedad_id") {
            //parse to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.codigo === "") {
            toast.error("Debe ingresar el código");
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            return;
        }
        if (form.zona_id === 0) {
            toast.error("Debe seleccionar una zona");
            return;
        }
        if (form.sociedad_id === 0) {
            toast.error("Debe seleccionar una sociedad");
            return;
        }
        dispatch(update_clients(token, id, form));
    };

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Cliente"
                            items={[
                                {label: "Clientes", link: "/clientes"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Cliente</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="codigo"
                                                name="codigo"
                                                label="Código"
                                                value={form.codigo}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                id="nombre"
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Zona</label>
                                                <Select
                                                    label="Zona"
                                                    name="zona_id"
                                                    options={zones.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    value={form.zona_id}
                                                    select={form.zona}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Sociedad</label>
                                                <Select
                                                    label="Sociedad"
                                                    name="sociedad_id"
                                                    options={societies.map((item) => ({
                                                        name: item.nombre,
                                                        id: item.id,
                                                    }))}
                                                    onChange={handleChange}
                                                    value={form.sociedad_id}
                                                    select={form.sociedad}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-1">
                                            <Space size={14} direction='horizontal'>
                                                <strong>Activo</strong>
                                                <Switch
                                                    checked={form.activo}
                                                    checkedChildren="SI"
                                                    unCheckedChildren="NO"
                                                    onChange={handleCheckedChange}
                                                />
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/clientes" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-calendar"
                                        onClick={() => {
                                        }}
                                        text="Guardar"
                                        theme="calendar"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateClient;
