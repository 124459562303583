import React, {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from "../../../components/breadcrumbs";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Search from "../../../components/search";
import Select from "../../../components/select";
import {
    create_batches,
    clear_status_batche,
    CREATE_BATCHES_SUCCESS,
    get_all_batches,
    searchStoreHouseBatches,
    detailBatches,
    get_report,
} from "../../../redux/batches_inventory";
import {
    create_movements,
    CREATE_MOVEMETS_SUCCESS,
    detailMovements,
    clear_status_movement,
    create_movement_processing, CREATE_MOVEMETS_PROCESSING_SUCCESS
} from "../../../redux/movements_inventory";
import {useHistory} from "react-router-dom";
import Modal from "../../../components/modal";
import Footer from "../../../components/footer";
import SelectInput from "../../../components/select";


const Inventory = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showDetail, setShowDetail] = useState(false);
    const [showSibarLeft, setShowSibarLeft] = useState(false);
    const [showSibarRight, setShowSibarRight] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [report, setReport] = useState(false);
    const [modalBatche, setModalBatche] = useState(false);
    const [modalMoveInventory, setModalMoveInventory] = useState(false);
    const [modalConfirmMoveInventory, setModalConfirmMoveInventory] = useState(false);
    const [modalMoveInventoryProcess, setModalMoveInventoryProcess] = useState(false);
    const [modalDetailMovements, setModalDetailMovements] = useState(false);
    const [modalProcessBox, setModalProcessBox] = useState(false);
    const [modalChronology, setModalChronology] = useState(false);

    const dataSelectedInit = {
        id: 0,
        codigo: '',
        cliente_id: '',
        codigo_cliente: '',
        nombre_cliente: '',
        kilataje_id: '',
        descripcion_kilataje: '',
        ley: '',
        peso_disponible: 0,
        registrado: '',
        fecha: '',
        movimientos: []
    }

    const [dataSelected, setDataSelected] = useState(dataSelectedInit);
    const [currentTotalWeight, setCurrentTotalWeight] = useState(0);
    const [percentageAvalible, setPercentageAvalible] = useState(0);
    const [dataStoreHouses, setDataStoreHouses] = useState([]);
    const [dataDetailMovements, setDataDetailMovements] = useState({});

    const _batches = useSelector(
        (state) => state.batche_module._get_all_batches
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        if (_batches.data.datos) {
            setData(_batches.data.datos);
        }
    }, [_batches]);

    const [permisos, setPermisos] = useState([]);

    useEffect(() => {
        if (props) {
            //converto to array
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    const [clients, setClients] = useState([]);

    const _clients = useSelector(
        (state) => state.client_module._get_all_clients
    );

    useEffect(() => {
        if (_clients.data.datos) {
            let value_clients = _clients.data.datos.filter(x => x.activo === true)
            setClients(value_clients);
        }
    }, [_clients]);


    const [jeweler, setJewelet] = useState([]);

    const _jeweler = useSelector(
        (state) => state.jeweler_module._get_all_jeweler
    );

    useEffect(() => {
        if (_jeweler.data.datos) {
            setJewelet(_jeweler.data.datos);
        }
    }, [_jeweler]);

    const [karatages, setKaratages] = useState([]);

    const _karatages = useSelector(
        (state) => state.karatage_module._get_all_karatages
    );

    useEffect(() => {
        if (_karatages.data.datos) {
            let value_karatages = _karatages.data.datos.filter(x => x.activo === true)
            setKaratages(value_karatages);
        }
    }, [_karatages]);

    const [storeHousesOrigin, setStoreHousesOrigin] = useState([]);
    const [storeHousesDestination, setStoreHousesDestination] = useState([]);

    const _store_houses = useSelector(
        (state) => state.store_house_module._get_all_store_houses
    );

    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _create_batche = useSelector((state) => state.batche_module._create_batches);

    useEffect(() => {
        if (_create_batche.status === 201) {
            // eslint-disable-next-line
            setForm(initForm);
            setShowOverlay(false);
            dispatch(clear_status_batche(CREATE_BATCHES_SUCCESS));
            dispatch(get_all_batches(token));
            setShowSibarRight(false);
            setShowSibarRight(false);
            setModalBatche(true);
        } else if (_create_batche.status !== 0) {
            if (_create_batche.message) {
                toast.error(_create_batche.message);
                dispatch(clear_status_batche(CREATE_BATCHES_SUCCESS));
            }
        }
        // eslint-disable-next-line
    }, [_create_batche, history, dispatch, token]);

    const initForm = {
        cliente_id: 0,
        kilataje_id: 0,
        ley: '',
        peso_inicial: 0,
    }

    // eslint-disable-next-line
    const [form, setForm] = useState(initForm);

    const initMoveInventory = {
        batch_id: 0,
        storehouse_origin_id: 0,
        storehouse_destination_id: 0,
        weight: 0,
        recipient_name: '',
        process: false,
        processing_id: '',
    }

    const [moveInventory, setMoveInventory] = useState(initMoveInventory);
    const [jeweler_id, setJeweler_id] = useState(0);
    const [weight_movement, setWeight_movement] = useState(0);
    const {_create_movement_processing} = useSelector((state) => state.movement_module);

    useEffect(() => {
        if (_create_movement_processing.status === 201) {
            toast.success("Proceso de movimiento de inventario creado correctamente");
            dispatch(clear_status_movement(CREATE_MOVEMETS_PROCESSING_SUCCESS));
            setModalProcessBox(false);
            history.push("/");
            dispatch(get_all_batches(token));
            // eslint-disable-next-line
            getStoreHousesOrigin(dataSelected.id);
            // eslint-disable-next-line
            getDetailBatches(dataSelected);
        } else if (_create_movement_processing.status !== 0) {
            if (_create_movement_processing.message) {
                toast.error(_create_movement_processing.message);
                dispatch(clear_status_movement(CREATE_MOVEMETS_PROCESSING_SUCCESS));
            }
        }
        // eslint-disable-next-line
    }, [_create_movement_processing, history, dispatch, token, dataSelected]);

    function createMoveProcessInventory() {
        let process_origin = '';
        let process_destination = '';
        let wm = parseFloat(dataDetailMovements.weight_movement);
        let materials = false;
        let materials_list = [];

        if (value_state === '') {
            process_origin = 'PROCESO INICIO';
            process_destination = 'REFINAR INICIO';
            if (jeweler_id === 0) {
                toast.error("Debe seleccionar un joyero");
                return;
            }
        } else if (value_state === 'REFINAR INICIO') {
            process_origin = 'REFINAR INICIO';
            process_destination = 'REFINAR FIN';
            if (parseFloat(weight_movement) <= 0 ) {
                toast.error("Verifique el monto a recibir ");
                return;
            }
            wm = parseFloat(weight_movement);
        } else if (value_state === 'REFINAR FIN') {
            process_origin = 'REFINAR FIN';
            process_destination = 'LIGAR INICIO';
            wm = parseFloat(weight_movement);
            if (jeweler_id === 0) {
                toast.error("Debe seleccionar un joyero");
                return;
            }
            if (dataMaterials.length > 0) {
                materials = true;
                materials_list = dataMaterials;
            }
        } else if (value_state === 'LIGAR INICIO') {
            process_origin = 'LIGAR INICIO';
            process_destination = 'LIGAR FIN';
            if (parseFloat(weight_movement) <= 0) {
                toast.error("Verifique el monto a recibir ");
                return;
            }
            wm = parseFloat(weight_movement);
        }

        const data = {
            movement_id: dataDetailMovements.movement_id,
            process_origin: process_origin,
            process_destination: process_destination,
            weight_movement: wm,
            jeweler_id: jeweler_id,
            materials: materials,
            materials_list: materials_list
        }

        dispatch(create_movement_processing(token, data));
    }

    const handleChange = (e) => {
        if (e.target.name === "cliente_id" || e.target.name === "kilataje_id") {
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleChange_weight_movement = (e) => {
        setWeight_movement(e.target.value)
    }

    const [currentWeight, setCurrentWeight] = useState(0);
    const [storehouse_origin, setStorehouse_origin] = useState('');
    const [storehouse_destination, setStorehouse_destination] = useState('');

    const handleChangeMoveInventory = (e) => {
        if (e.target.name === "storehouse_origin_id" || e.target.name === "storehouse_destination_id") {
            setMoveInventory({
                ...moveInventory,
                [e.target.name]: parseInt(e.target.value),
            });
            if (e.target.name === "storehouse_origin_id") {
                document.getElementById("storehouse_destination_id").selectedIndex = 0;
                // eslint-disable-next-line
                storeHousesOrigin.map(element => {
                    if (parseInt(element.storehouse_id) === parseInt(e.target.value)) {
                        setCurrentWeight(parseFloat(element.current_weight));
                        setStorehouse_origin(element.storehouse_name);
                        if (element.storehouse_name === 'ENTRADA') {
                            const value_stores = _store_houses.data.datos.filter(x => (x.nombre === 'PROCESO' || x.nombre === 'SALIDA'));
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'PROCESO') {
                            const value_stores = _store_houses.data.datos.filter(x => x.nombre === 'CAJA 1');
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'CAJA 1') {
                            const value_stores = _store_houses.data.datos.filter(x => x.nombre === 'CAJA 2');
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'CAJA 2') {
                            const value_stores = _store_houses.data.datos.filter(x => x.nombre === 'MANUFACTURA');
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'MANUFACTURA') {
                            const value_stores = _store_houses.data.datos.filter(x => (x.nombre === 'CAJA 2' || x.nombre === 'PRODUCTO TERMINADO'));
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'PRODUCTO TERMINADO') {
                            const value_stores = _store_houses.data.datos.filter(x => x.nombre === 'SALIDA');
                            setStoreHousesDestination(value_stores);
                        } else if (element.storehouse_name === 'SALIDA') {
                            setStoreHousesDestination([])
                        }
                    }
                })
            }
            if (e.target.name === "storehouse_destination_id") {
                // eslint-disable-next-line
                storeHousesDestination.filter(element => {
                    if (parseInt(element.id) === parseInt(e.target.value)) {
                        setStorehouse_destination(element.nombre);
                    }
                });
            }
        } else {
            setMoveInventory({
                ...moveInventory,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handleSubmitMoveInventory = (e) => {
        e.preventDefault();
        if (moveInventory.storehouse_origin_id === 0) {
            toast.error("Debe seleccionar una caja de origen");
            return;
        }
        if (moveInventory.storehouse_destination_id === 0) {
            toast.error("Debe seleccionar una caja de destino");
            return;
        }
        if (moveInventory.weight === 0) {
            toast.error("El peso a mover debe ser mayor a cero");
            return;
        } else if (moveInventory.weight > currentWeight) {
            toast.error("El peso a mover es mayor al peso disponible");
            return;
        }
        if (moveInventory.recipient_name === "") {
            toast.error("Debe ingresar el destinatario de entrega");
            return;
        }

        setMoveInventory({
            ...moveInventory,
            batch_id: dataSelected.id
        })

        const value_process = _store_houses.data.datos.filter(x => x.id === parseInt(moveInventory.storehouse_origin_id));
        if (value_process.length > 0) {
            if (value_process[0].nombre === 'PROCESO') {

            } else {
                setModalConfirmMoveInventory(true);
            }
        }
    }

    const {_create_movement} = useSelector((state) => state.movement_module);

    useEffect(() => {
        if (_create_movement.status === 201) {
            toast.success("Movimiento de inventario creado correctamente");
            dispatch(clear_status_movement(CREATE_MOVEMETS_SUCCESS));
            history.push("/");
            dispatch(get_all_batches(token));
            // eslint-disable-next-line
            getStoreHousesOrigin(dataSelected.id);
            // eslint-disable-next-line
            getDetailBatches(dataSelected);
            setModalConfirmMoveInventory(false);
            setModalMoveInventory(false);
            setModalMoveInventoryProcess(false);
        } else if (_create_movement.status !== 0) {
            if (_create_movement.message) {
                toast.error(_create_movement.message);
                dispatch(clear_status_movement(CREATE_MOVEMETS_SUCCESS));
            }
        }
        // eslint-disable-next-line
    }, [_create_movement, history, dispatch, token, dataSelected]);

    function confirmMoveInventory() {
        dispatch(create_movements(token, moveInventory))
    }

    const [recipient_name_process, setRecipient_name_process] = useState('');

    async function confirmMoveInventoryProcess() {
        let storehouse_origin_id = 0;
        let storehouse_destination_id = 0;
        const response = await searchStoreHouseBatches(token, dataSelected.id);
        if (response && response.data && response.data.datos) {
            // eslint-disable-next-line
            response.data.datos.storehouses_list.map((x) => {
                if (x.storehouse_name === 'PROCESO') {
                    storehouse_origin_id = x.storehouse_id;
                }
                if (x.storehouse_name === 'CAJA 1') {
                    storehouse_destination_id = x.storehouse_id;
                }
            })
        }
        const data = {
            batch_id: dataSelected.id,
            storehouse_origin_id: storehouse_origin_id,
            storehouse_destination_id: storehouse_destination_id,
            weight: parseFloat(dataDetailMovements.processing_details.final_weight),
            recipient_name: recipient_name_process,
            process: false,
            processing_id: dataDetailMovements.processing_details.processing_id,
        }
        dispatch(create_movements(token, data))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.cliente_id === 0) {
            toast.error("Debe seleccionar un cliente");
            return;
        }
        if (form.sociedad_id === 0) {
            toast.error("Debe seleccionar un kilate");
            return;
        }
        if (form.ley === "") {
            toast.error("Debe ingresar una ley");
            return;
        }
        if (form.peso_inicial === 0) {
            toast.error("El peso inicial debe ser mayor a cero");
            return;
        }
        dispatch(create_batches(token, form))
    }

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setData(_batches.data.datos);
            return;
        }
        setData([]);
        let value = e.target.value;
        value = value.trim();
        let re = new RegExp(value, "i");
        // eslint-disable-next-line
        let filtro = _batches.data.datos.filter(element => {
            if ((element.codigo.match(re) || element.nombre_cliente.match(re) || element.descripcion_kilataje.match(re) || element.ley.match(re)) != null) {
                return element;
            }
        })
        setData(filtro);
    }
    const _get_report = useSelector((store) => store.batche_module._get_report);

    useEffect(() => {
        setReport(_get_report.status);
    }, [_get_report])

    const handleGenerateReport = () => {
        let final = []
        data.forEach(e => {
            final.push(e.id)
        })

        dispatch(get_report({'list_ids': final}, token))
    }

    const [count, setCount] = useState(0);
    const [HasMore, setHasMore] = useState(true);

    const loadFunc = () => {
        if (data) {
            if (data.length > count) {
                setCount(count + 10);
            }
            setHasMore(false);
        }

    };

    function onItemClickListener(item) {
        setShowDetail(true);
        getDetailBatches(item);
        getStoreHousesOrigin(item.id);
    }

    const [storeHousesBatches, setStoreHousesBatches] = useState([]);

    async function getStoreHousesOrigin(id) {
        const response = await searchStoreHouseBatches(token, id);
        if (response && response.data && response.data.datos) {
            setCurrentTotalWeight(response.data.datos.current_total_weight);
            let value = response.data.datos.storehouses_list.filter(x => (parseFloat(x.current_weight) > 0) && (x.storehouse_name !== 'PROCESO') && (x.storehouse_name !== 'SALIDA'));
            setStoreHousesOrigin(value);
            setStoreHousesBatches(response.data.datos.storehouses_list);
        }
    }

    async function getDetailMovements(id) {
        const response = await detailMovements(token, id);
        if (response && response.data && response.data.datos) {
            setDataDetailMovements(response.data.datos);
        }
    }

    useEffect(() => {
        if (storeHousesBatches && dataSelected) {
            // eslint-disable-next-line
            const percentage = parseFloat(currentTotalWeight) * 100 / parseFloat(dataSelected.peso_disponible);
            setPercentageAvalible(percentage);
            // eslint-disable-next-line
            const value_store_houses = storeHousesBatches.map((x) => {
                // eslint-disable-next-line
                const percentageX = (parseFloat(x.current_weight) * 100) / parseFloat(dataSelected.peso_disponible);
                return {
                    id: x.storehouse_id,
                    nombre: x.storehouse_name,
                    peso: x.current_weight,
                    porcentaje: percentageX.toString(),
                }
            });
            setDataStoreHouses(value_store_houses);
        }
    }, [storeHousesBatches, currentTotalWeight, dataSelected]);

    const [value_button, setValue_button] = useState(0);
    const [value_state, setValueState] = useState('');
    const [value_materials, setValueMaterials] = useState([]);

    useEffect(() => {
        if (dataDetailMovements && dataDetailMovements.processing_details && dataDetailMovements.processing_details.processing_movements) {
            // eslint-disable-next-line
            dataDetailMovements.processing_details.processing_movements.map((x) => {
                setValueState(x.movement_name);
                setWeight_movement(parseFloat(x.weight_movement));
                if (x.movement_name === 'LIGAR INICIO') {
                    setValueMaterials(x.materials);
                }
                if (x.movement_name === 'LIGAR FIN') {
                    setValue_button(1);
                }
            });
            if (dataDetailMovements.processing_details.state_name === 'TRANSFERIDO') {
                setValue_button(2);
            }
        }
    }, [dataDetailMovements]);

    async function getDetailBatches(item) {
        const response = await detailBatches(token, item.id);
        if (response && response.data && response.data.datos) {
            const data = response.data.datos;
            setDataSelected({
                id: data.batch_id,
                codigo: data.batch_code,
                cliente_id: data.client_id,
                codigo_cliente: data.client_code,
                nombre_cliente: data.client_name,
                kilataje_id: data.karatage_id,
                descripcion_kilataje: data.karatage_name,
                ley: data.purity,
                peso_disponible: data.starting_weight,
                registrado: data.user_create,
                fecha: data.date_create,
                movimientos: data.movements
            })
        } else {
            setDataSelected(item)
        }
    }

    const [dataMaterials, setDataMaterials] = useState([]);

    function create_div_materials() {
        setDataMaterials([
            ...dataMaterials,
            {
                processing_id: dataDetailMovements.processing_details.processing_id,
                material_id: 0,
                weight: 0,
            },
        ])
    }

    const remove_div_material = index => {
        const rows = [...dataMaterials];
        rows.splice(index, 1);
        setDataMaterials(rows);
    };

    const handleChange_weight = (index, value) => {
        const list = [...dataMaterials];
        list[index].weight = value;
        setDataMaterials(list);
    };

    const [materials, setMaterials] = useState([]);

    const _materials = useSelector(
        (state) => state.material_module._get_all_materials
    );

    useEffect(() => {
        if (_materials.data.datos) {
            setMaterials(_materials.data.datos);
        }
    }, [_materials]);

    const handleChange_material = (index, value) => {
        const list = [...dataMaterials];
        list[index].material_id = value;
        setDataMaterials(list);
    };

    async function filterMovements(name) {
        const response = await detailBatches(token, dataSelected.id);
        if (response && response.data && response.data.datos) {
            const data = response.data.datos;
            let filtro = '';
            if (name !== 'TODOS'){
                // eslint-disable-next-line
                filtro = data.movements.filter(element => {
                    if (element.storehouse_origin_name === name || element.storehouse_destination_name === name) {
                        return element;
                    }
                })
            } else {
                filtro = data.movements;
            }

            setDataSelected({
                ...dataSelected,
                movimientos: filtro
            });
        }
        // console.log(dataSelected)
    }

    const filter_options = [
        {name: "HOY", id: 1},
        {name: "AYER", id: 2},
        {name: "ÚLTIMOS 7 DÍAS", id: 3},
        {name: "ÚLTIMOS 30 DÍAS", id: 4},
        {name: 'Rango de fechas', id: 5}
    ];


    const [range_date, setRangeDate] = useState({
        visible: false,
        date_start: '',
        date_end: ''
    })

    const filterToday = (e) => {
        let batches = [];
        let today = new Date();
        batches = data;

        let actual = _batches.data.datos

        if (e.target.value === "0") {
            setData(actual)
        }

        //HOY
        if (e.target.value === "1") {
            // eslint-disable-next-line
            batches = actual.filter((item) => {
                if (item.fecha_creacion) {
                    let fecha = new Date(item.fecha_creacion + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return item;
                    }
                }
            })
            setData(batches);

            return;
        }

        // AYER
        if (e.target.value === "2") {
            let today = new Date();
            today.setDate(today.getDate() - 1)
            // eslint-disable-next-line
            batches = actual.filter((item) => {
                if (item.fecha_creacion) {
                    let fecha = new Date(item.fecha_creacion + ' 00:00:00');
                    if (fecha.getDate() === today.getDate() && fecha.getMonth() === today.getMonth() && fecha.getFullYear() === today.getFullYear()) {
                        return item;
                    }
                }
            })
            setData(batches);
            return;
        }

        //ÚLTIMOS 7 DÍAS
        if (e.target.value === "3") {
            let today = new Date();

            today.setDate(today.getDate() - 7)
            // eslint-disable-next-line
            batches = actual.filter((item) => {
                if (item.fecha_creacion) {
                    let fecha = new Date(item.fecha_creacion + ' 00:00:00');
                    if (fecha.getTime() <= new Date().getTime() && fecha.getTime() >= today.getTime()) {
                        return item;
                    }
                }
            })
            setData(batches);
            return;
        }

        //ÚLTIMOS 30 DÍAS
        if (e.target.value === "4") {
            let today = new Date();
            today.setDate(today.getDate() - 30)
            // eslint-disable-next-line
            batches = actual.filter((item) => {
                if (item.fecha_creacion) {
                    let fecha = new Date(item.fecha_creacion + ' 00:00:00');
                    if (fecha.getTime() >= today.getTime() && fecha.getTime() <= new Date().getTime()) {
                        return item;
                    }
                }
            })
            setData(batches);

            return;
        }


        if (e.target.value === '5') {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: true
            })
        } else {
            setRangeDate({
                date_end: '',
                date_start: '',
                visible: false
            })
        }

    }

    const handleFilterRangeDate = (e) => {
        if (e.target.name === 'date_start') {
            range_date.date_start = e.target.value
        }
        if (e.target.name === 'date_end') {
            range_date.date_end = e.target.value
        }

        let actual = _batches.data.datos

        console.log(actual)

        if (range_date.date_start !== '' && range_date.date_end !== '') {
            let date_start = new Date(range_date.date_start + ' 00:00:00');
            let date_end = new Date(range_date.date_end + ' 00:00:00');
            console.log(date_start)
            console.log(date_end)
            // eslint-disable-next-line
            let final = actual.filter(x => {
                    if (x.fecha_creacion) {
                        let fecha = new Date(x.fecha_creacion + ' 00:00:00');
                        console.log('fecha')
                        console.log(fecha)
                        if (fecha.getTime() >= date_start.getTime() && fecha.getTime() <= date_end.getTime()) {
                            return x;
                        }
                    }
                }
            )

            setData(final)
        }
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-wrapper">

                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 ">
                        <Breadcrumbs
                            title="Inventarios"
                            items={[{label: "Lotes", link: "/"}]}
                            onclick
                        />
                    </div>
                </div>
                <div className="content-body">

                    <div className="content-area-wrapper" style={{margin: 0}}>


                        <div className={`sidebar-left ${showSibarLeft ? 'show' : ''}`}>
                            <div className="sidebar">

                                <div className="sidebar-content email-app-sidebar d-flex">
                                    {/* sidebar close icon */}
                                    <span className="sidebar-close-icon" onClick={
                                        () => {
                                            setShowSibarLeft(false)
                                            setShowOverlay(false)
                                        }
                                    }>
                                        <i className="bx bx-x"/>
                                    </span>
                                    {/* sidebar close icon */}
                                    <div className="email-app-menu">
                                        <div className="form-group form-group-compose">
                                            {/* compose button  */}
                                            {
                                                permisos.filter(element => element.codigo === "add_batches").length > 0 ?
                                                    <Button
                                                        text="Crear"
                                                        onChange={() => {
                                                            setShowSibarRight(!showSibarRight);
                                                            setShowSibarLeft(false);
                                                            setShowDetail(false);
                                                            setShowOverlay(!showSibarRight);
                                                            setForm(initForm);
                                                            document.getElementById("cliente_id").selectedIndex = 0;
                                                            document.getElementById("kilataje_id").selectedIndex = 0;
                                                        }}
                                                        theme="calendar"
                                                        classes=" btn-block my-2 compose-btn"
                                                    />
                                                    : null
                                            }

                                        </div>
                                        <div className="sidebar-menu-list">
                                            {/* sidebar menu  */}
                                            <div className="list-group list-group-messages">
                                                <label>Filtrar</label>
                                                <SelectInput
                                                    id="filter_batches"
                                                    name="filter_batches"
                                                    options={filter_options}
                                                    onChange={filterToday}
                                                />
                                                <ul className="list-unstyled mb-0">

                                                    {
                                                        range_date.visible ? <>
                                                            <li className=" mr-2 mb-1">
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Inicio"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_start'

                                                                />
                                                                <Input
                                                                    type="date"
                                                                    label="Fecha Fin"
                                                                    onChange={handleFilterRangeDate}
                                                                    name='date_end'

                                                                />
                                                            </li>
                                                            <hr/>
                                                        </> : null
                                                    }
                                                </ul>
                                            </div>
                                            {/* sidebar label end */}
                                        </div>
                                    </div>
                                </div>
                                {/* User new mail right area */}
                                {

                                    permisos.filter(element => element.codigo === "add_batches").length > 0 ?
                                        <div className={`compose-new-mail-sidebar ${showSibarRight ? 'show' : ''}`}
                                             style={{
                                                 overflowY: 'scroll',
                                             }}
                                        >
                                            <div className="card shadow-none quill-wrapper p-0">
                                                <div className="card-header">
                                                    <h3 className="card-title" id="lote">
                                                        Nuevo lote
                                                    </h3>
                                                    <button type="button" className="close close-icon"
                                                            onClick={() => {
                                                                setShowSibarRight(false);
                                                                setShowSibarLeft(false);
                                                                setShowOverlay(false);
                                                            }}
                                                    >
                                                        <i className="bx bx-x"/>
                                                    </button>
                                                </div>
                                                {/* form start */}
                                                <form onSubmit={handleSubmit} id="compose-form">
                                                    <div className="card-content">
                                                        <div className="card-body pt-0">
                                                            <label className="form-label">Cliente</label>
                                                            <Select
                                                                id="cliente_id"
                                                                label="Cliente"
                                                                name="cliente_id"
                                                                options={clients.map((item) => ({
                                                                    name: item.nombre,
                                                                    id: item.id,
                                                                }))}
                                                                onChange={handleChange}
                                                            />
                                                            <label className="form-label">Kilates</label>
                                                            <Select
                                                                id="kilataje_id"
                                                                label="Kilates"
                                                                name="kilataje_id"
                                                                options={karatages.map((item) => ({
                                                                    name: item.descripcion,
                                                                    id: item.id,
                                                                }))}
                                                                onChange={handleChange}
                                                            />
                                                            <Input
                                                                type="text"
                                                                name="ley"
                                                                id="ley"
                                                                label="Ley"
                                                                onChange={handleChange}
                                                                placeholder="Ley"
                                                                value={form.ley}
                                                            />
                                                            <Input
                                                                type="number"
                                                                name="peso_inicial"
                                                                id="peso_inicial"
                                                                label="Peso inicial (gr)"
                                                                onChange={handleChange}
                                                                placeholder=""
                                                                value={form.peso_inicial}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="card-footer d-flex justify-content-end pt-0">
                                                        <Button
                                                            text={
                                                                <>
                                                                    <i className="bx bx-x mr-25"/>
                                                                    <span className="d-sm-inline d-none">Cancelar</span>
                                                                </>
                                                            }

                                                            theme="light-secondary"
                                                            type="reset"
                                                            classes="cancel-btn mr-1"
                                                            onChange={() => {
                                                                setShowSibarRight(false);
                                                                setShowOverlay(!showOverlay);
                                                            }}
                                                        />
                                                        <Button
                                                            text="Guardar"
                                                            theme="calendar"
                                                            type="submit"
                                                        />
                                                    </div>
                                                </form>
                                                {/* form start end*/}
                                            </div>
                                        </div> : null}
                                {/*/ User Chat profile right area */}
                            </div>
                        </div>
                        <div className="content-right">
                            <div className="content-overlay"/>
                            <div className="content-wrapper">
                                <div className="content-header row"/>
                                <div className="content-body">
                                    {/* email app overlay */}
                                    <div className={`app-content-overlay ${showOverlay ? 'show' : ''}`}
                                         onClick={
                                             () => {
                                                 setShowSibarRight(!showSibarRight);
                                                 setShowSibarLeft(false);
                                                 setShowDetail(false);
                                                 setShowOverlay(!showOverlay);
                                             }
                                         }
                                    />
                                    <div className="email-app-area">
                                        {/* Email list Area */}
                                        <div className="email-app-list-wrapper">
                                            <div className="email-app-list">
                                                <div className="email-action">
                                                    {/* action left start here */}

                                                    {/* action left end here *-/}

                      {/* action right start here */}
                                                    <div
                                                        className="action-right d-flex flex-grow-1 align-items-center justify-content-around">
                                                        {/* search bar  */}
                                                        <div className="email-fixed-search flex-grow-1">
                                                            <div className="sidebar-toggle d-block d-lg-none" onClick={
                                                                () => {
                                                                    setShowSibarLeft(!showSibarLeft);
                                                                    setShowOverlay(!showOverlay);
                                                                }
                                                            }>
                                                                <i className="bx bx-menu"/>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <Search placeholder={"Número de lote"}
                                                                            onChange={handleSearch}
                                                                    />
                                                                </div>
                                                                <div className="col-2">
                                                                    {!report ? <Button
                                                                        text="Exportar"
                                                                        theme="calendar"
                                                                        type="button"
                                                                        classes="btn"
                                                                        onClick={() => handleGenerateReport()}
                                                                    /> : <div class="spinner-border text-success"
                                                                              role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* pagination and page count */}
                                                    </div>
                                                </div>
                                                {/* / action right */}

                                                {/* email user list start */}
                                                <div className="email-user-list list-group">
                                                    <ul className="users-list-wrapper media-list" style={{
                                                        overflowY: 'scroll',
                                                    }}>
                                                        <InfiniteScroll
                                                            pageStart={0}
                                                            loadMore={() => loadFunc()}
                                                            hasMore={HasMore}
                                                            useWindow={false}

                                                            loader={
                                                                <div className="col-12 d-flex justify-content-center">
                                                                    <div
                                                                        className="spinner-border text-success"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    {" "}
                                                                </div>
                                                            }
                                                        >
                                                            <table className="table table-striped" id="table">
                                                                <thead>
                                                                <tr className="text-center">
                                                                    <th>Código</th>
                                                                    <th>Fecha</th>
                                                                    <th>Cliente</th>
                                                                    <th>Kilate</th>
                                                                    <th>Ley</th>
                                                                    <th>Disponible</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {data.map((item, index) => (
                                                                    <tr onClick={() => {
                                                                        onItemClickListener(item)
                                                                    }} style={{cursor: 'pointer'}}>
                                                                        <td>{item.codigo}</td>
                                                                        <td>{item.fecha_creacion}</td>
                                                                        <td>{item.nombre_cliente}</td>
                                                                        <td>{item.descripcion_kilataje}</td>
                                                                        <td>{item.ley}</td>
                                                                        <td>{item.peso_disponible} gr</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                        </InfiniteScroll>
                                                    </ul>
                                                    {/* email user list end */}

                                                    {/* no result when nothing to show on list */}
                                                    <div className="no-results">
                                                        <i className="bx bx-error-circle font-large-2"/>
                                                        <h5>No Items Found</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Email list Area */}

                                        {/* Detailed Email View */}
                                        <div className={`email-app-details ${showDetail ? 'show' : ''} `}>
                                            {/* email detail view header */}
                                            <div className="email-detail-header">
                                                <div className="email-header-left d-flex align-items-center mb-1">
                                                      <span className="go-back mr-50" onClick={
                                                          () => {
                                                              setShowDetail(false);
                                                          }
                                                      }>
                                                        <span className="fonticon-wrap d-inline">
                                                          <i className="bx bx-arrow-back"/>
                                                        </span>
                                                      </span>
                                                    <h5 className="font-weight-normal mb-0">
                                                        Lote # {dataSelected.codigo}
                                                    </h5>
                                                </div>
                                            </div>
                                            {/* email detail view header end*/}
                                            <div className="email-scroll-area" style={{overflowY: 'scroll',}}>
                                                {/* email details  */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="collapsible email-detail-head">
                                                            <div className="row">
                                                                <div className="col-7">
                                                                    <div className="card">
                                                                        <div className="card-content">
                                                                            <div className="card-body m-0">
                                                                                <h5>Información del lote</h5>
                                                                                <div className="d-flex">
                                                                                    <div style={{width: '80%'}}>
                                                                                        <strong>Peso
                                                                                            inicial</strong><br/>
                                                                                        <strong>Kilataje</strong><br/>
                                                                                        <strong>Ley</strong><br/>
                                                                                        <strong>Código de
                                                                                            cliente</strong><br/>
                                                                                        <strong>Nombre de
                                                                                            cliente</strong><br/>
                                                                                    </div>
                                                                                    <div style={{width: '20%'}}
                                                                                         className="text-right">
                                                                                        <span>{parseFloat(dataSelected.peso_disponible).toFixed(2)} gr</span><br/>
                                                                                        <span>{dataSelected.descripcion_kilataje}</span><br/>
                                                                                        <span>{dataSelected.ley}</span><br/>
                                                                                        <span>{dataSelected.codigo_cliente}</span><br/>
                                                                                        <span>{dataSelected.nombre_cliente}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-content">
                                                                            <div className="card-body m-0">
                                                                                <h5 className="mb-1">Movimientos</h5>
                                                                                {dataSelected.movimientos.map((item) => (
                                                                                    <div className="border_div"
                                                                                         onClick={async() => {
                                                                                             setModalDetailMovements(true);
                                                                                             setValueState('');
                                                                                             setValue_button(0);
                                                                                             setWeight_movement(0);
                                                                                             await getDetailMovements(item.movement_id);
                                                                                         }} style={{cursor: "pointer"}}>
                                                                                        <div className="d-flex">
                                                                                            <div style={{width: '30%'}}>
                                                                                                <small>Fecha de
                                                                                                    registro</small><br/>
                                                                                                <strong>{item.date_create}</strong>
                                                                                            </div>
                                                                                            <div style={{width: '20%'}}>
                                                                                                <small>Caja
                                                                                                    origen</small><br/>
                                                                                                <strong>{item.storehouse_origin_name}</strong>
                                                                                            </div>
                                                                                            <div style={{width: '20%'}}>
                                                                                                <small>Caja
                                                                                                    destino</small><br/>
                                                                                                <strong>{item.storehouse_destination_name}</strong>
                                                                                            </div>
                                                                                            <div style={{width: '20%'}}>
                                                                                                <small>Peso</small><br/>
                                                                                                <strong>{parseFloat(item.weight_movement).toFixed(2)} gr</strong>
                                                                                            </div>
                                                                                            <div style={{width: '10%'}}
                                                                                                 className="text-center">
                                                                                                {(item.storehouse_destination_name === 'PROCESO' && item.processing_state === 'EN PROCESO') && (
                                                                                                    <img
                                                                                                        src={window.location.origin + "/base/app-assets/images/icon/view-movement.png"}
                                                                                                        alt=""
                                                                                                        height="30"
                                                                                                        width="30"
                                                                                                        className="mr-1"/>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-5">
                                                                    <div className="card">
                                                                        <div className="card-content">
                                                                            <div className="card-body m-0 text-center">
                                                                                <h5>Registro de movimientos</h5>
                                                                                <div className="mt-1">
                                                                                    <button type="button"
                                                                                            className="btn btn-calendar"
                                                                                            onClick={() => {
                                                                                                setModalMoveInventory(true);
                                                                                                setMoveInventory(initMoveInventory);
                                                                                                setCurrentWeight(0);
                                                                                                getStoreHousesOrigin(dataSelected.id);
                                                                                                setStoreHousesDestination([]);
                                                                                                document.getElementById("storehouse_origin_id").selectedIndex = 0;
                                                                                                document.getElementById("storehouse_destination_id").selectedIndex = 0
                                                                                            }}><img
                                                                                        src={window.location.origin + "/base/app-assets/images/icon/movement.png"}
                                                                                        alt="" height="20" width="20"
                                                                                        className="mr-1"/>
                                                                                        Mover inventario
                                                                                    </button>
                                                                                </div>
                                                                                <div className="mt-1">
                                                                                    <button type="button"
                                                                                            className="btn btn-calendar"
                                                                                            onClick={() => {
                                                                                                setModalChronology(true);
                                                                                            }}><img
                                                                                        src={window.location.origin + "/base/app-assets/images/icon/timeline.png"}
                                                                                        alt="" height="20" width="20"
                                                                                        className="mr-1"/> Cronología
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card">
                                                                        <div className="card-content">
                                                                            <div className="card-body m-0">
                                                                                <h5>Distribución entre cajas</h5>
                                                                                <div className="mt-1">
                                                                                    <div style={{
                                                                                        background: '#0D2339',
                                                                                        padding: 10,
                                                                                        cursor: 'pointer'
                                                                                    }} onClick={() => {
                                                                                        filterMovements("TODOS")
                                                                                    }}>
                                                                                        <div className="d-flex">
                                                                                            <div style={{
                                                                                                width: '15%',
                                                                                                marginTop: 5
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    width: 32,
                                                                                                    height: 32,
                                                                                                    background: '#FFC912',
                                                                                                    borderRadius: 50
                                                                                                }}
                                                                                                     className="text-center">
                                                                                                    <img
                                                                                                        src={window.location.origin + "/base/app-assets/images/icon/strongbox.png"}
                                                                                                        alt=""
                                                                                                        height="15"
                                                                                                        width="15"
                                                                                                        style={{marginTop: 8}}/>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{width: '85%'}}>
                                                                                                <div className="d-flex">
                                                                                                    <div
                                                                                                        style={{width: '60%'}}>
                                                                                                        <strong
                                                                                                            style={{color: '#FFC912'}}>Total
                                                                                                            disponible</strong>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="text-right"
                                                                                                        style={{width: '40%'}}>
                                                                                                        <strong
                                                                                                            style={{color: '#FFC912'}}>{parseFloat(currentTotalWeight).toFixed(2)} gr</strong>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{width: '100%'}}>
                                                                                                    <div
                                                                                                        className="progress mt-1">
                                                                                                        <div
                                                                                                            className="progress-bar"
                                                                                                            role="progressbar"
                                                                                                            style={{width: percentageAvalible + '%'}}
                                                                                                            aria-valuenow="100"
                                                                                                            aria-valuemin="0"
                                                                                                            aria-valuemax="100"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {dataStoreHouses.map((item) => (
                                                                                        <div style={{
                                                                                            background: '#FFFFFF',
                                                                                            padding: 10,
                                                                                            cursor: 'pointer'
                                                                                        }} onClick={() => {
                                                                                            filterMovements(item.nombre)
                                                                                        }}>
                                                                                            <div className="d-flex">
                                                                                                <div style={{
                                                                                                    width: '15%',
                                                                                                    marginTop: 5
                                                                                                }}>
                                                                                                    <div style={{
                                                                                                        width: 32,
                                                                                                        height: 32,
                                                                                                        background: '#FFC912',
                                                                                                        borderRadius: 50
                                                                                                    }}
                                                                                                         className="text-center">
                                                                                                        <img
                                                                                                            src={window.location.origin + "/base/app-assets/images/icon/strongbox.png"}
                                                                                                            alt=""
                                                                                                            height="15"
                                                                                                            width="15"
                                                                                                            style={{marginTop: 8}}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    style={{width: '85%'}}>
                                                                                                    <div
                                                                                                        className="d-flex">
                                                                                                        <div
                                                                                                            style={{width: '60%'}}>
                                                                                                            <span>{item.nombre}</span>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="text-right"
                                                                                                            style={{width: '40%'}}>
                                                                                                            <span>{parseFloat(item.peso).toFixed(2)} gr</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        style={{width: '100%'}}>
                                                                                                        <div
                                                                                                            className="progress mt-1">
                                                                                                            <div
                                                                                                                className="progress-bar"
                                                                                                                role="progressbar"
                                                                                                                style={{width: item.porcentaje + '%'}}
                                                                                                                aria-valuenow="100"
                                                                                                                aria-valuemin="0"
                                                                                                                aria-valuemax="100"></div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* email details  end*/}
                                                <div className="row px-2 mb-4">
                                                    {/* quill editor for reply message */}
                                                    <div className="col-12 px-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*/ Detailed Email View */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            <Modal className="modal-main" show={modalBatche} style={{width: "25%"}}>
                <div className="card-body">
                    <div className="text-center">
                        <img
                            src={window.location.origin + "/base/app-assets/images/icon/success.png"}
                            alt="" height="96" width="96" className="mb-5"/>
                        <h4>Lote registrado de forma exitosa</h4>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" className="btn btn-calendar" onClick={() => {
                        setModalBatche(false);
                    }}> OK
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalMoveInventory} style={{width: "35%"}}>
                <form onSubmit={handleSubmitMoveInventory} id="compose-form">
                    <div className="card-header d-flex">
                        <div className="text-center" style={{width: '90%'}}>
                            <strong style={{
                                background: '#FFC912', paddingTop: 5,
                                paddingBottom: 5, paddingLeft: 15, paddingRight: 15,
                                borderRadius: 30, fontSize: 18, color: '#0D2339'
                            }}
                            >Mover inventario</strong>
                        </div>
                        <div style={{width: '10%'}} className="text-right">
                            <button type="button" className="btn btn-calendar badge-circle" onClick={() => {
                                setModalMoveInventory(false);
                            }}> X
                            </button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="mb-1">
                            <div className="mb-1" style={{
                                background: '#FFC912', paddingTop: 5,
                                paddingBottom: 5, paddingLeft: 15, paddingRight: 15
                            }}>
                                <span style={{color: '#0D2339', fontSize: 15}}><img
                                    src={window.location.origin + "/base/app-assets/images/icon/strongbox.png"}
                                    alt="" height="30" width="30" className="mr-1"/>CAJA ORIGEN</span>
                            </div>
                            {(storeHousesOrigin) && (
                                <Select
                                    id="storehouse_origin_id"
                                    name="storehouse_origin_id"
                                    options={storeHousesOrigin.map((item) => ({
                                        name: item.storehouse_name,
                                        id: item.storehouse_id,
                                    }))}
                                    onChange={handleChangeMoveInventory}
                                />
                            )}
                            <strong>Disponible: {currentWeight} (gr)</strong>
                        </div>
                        <div className="mb-1">
                            <div className="mb-1" style={{
                                background: '#FFC912', paddingTop: 5,
                                paddingBottom: 5, paddingLeft: 15, paddingRight: 15
                            }}>
                                <span style={{color: '#0D2339', fontSize: 15}}><img
                                    src={window.location.origin + "/base/app-assets/images/icon/strongbox.png"}
                                    alt="" height="30" width="30" className="mr-1"/>CAJA DESTINO</span>
                            </div>
                            <Select
                                id="storehouse_destination_id"
                                name="storehouse_destination_id"
                                options={storeHousesDestination.map((item) => ({
                                    name: item.nombre,
                                    id: item.id,
                                }))}
                                onChange={handleChangeMoveInventory}
                            />
                            <Input
                                type="number"
                                name="weight"
                                id="weight"
                                label="Peso a mover (gr)"
                                onChange={handleChangeMoveInventory}
                                placeholder="0"
                                value={moveInventory.weight}
                            />
                            <Input
                                type="text"
                                name="recipient_name"
                                id="recipient_name"
                                label="Entregado a"
                                onChange={handleChangeMoveInventory}
                                value={moveInventory.recipient_name}
                            />
                        </div>
                    </div>
                    <div className="card-footer text-center">
                        <Button
                            text="Mover"
                            theme="calendar"
                            type="submit"
                        />
                    </div>
                </form>
            </Modal>
            <Modal className="modal-main" show={modalConfirmMoveInventory} style={{width: "25%"}}>
                <div className="card-body">
                    <div className="text-center mb-3">
                        <img
                            src={window.location.origin + "/base/app-assets/images/icon/ayuda.png"}
                            alt="" height="100" width="100" className="mb-5"/>
                        <h5>Se registrará el siguiente movimiento de inventario:</h5>
                    </div>
                    <div>
                        <div className="d-flex">
                            <div className="mr-2">
                                <img
                                    src={window.location.origin + "/base/app-assets/images/icon/entra.png"}
                                    alt="" height="50" width="50" className="mb-5"/>
                            </div>
                            <div>
                                <span>Desde:</span> <strong>{storehouse_origin}</strong><br/>
                                <span>Peso:</span> <strong>{parseFloat(currentWeight).toFixed(2)} gr</strong>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="mr-2">
                                <img
                                    src={window.location.origin + "/base/app-assets/images/icon/sale.png"}
                                    alt="" height="50" width="50" className="mb-5"/>
                            </div>
                            <div>
                                <span>Hacia:</span> <strong>{storehouse_destination}</strong><br/>
                                <span>Peso:</span> <strong>{parseFloat(moveInventory.weight).toFixed(2)} gr</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-center">
                    <button type="button" className="btn btn-calendar mr-2" onClick={() => {
                        setModalConfirmMoveInventory(false);
                    }}> Cancelar
                    </button>
                    <button type="button" className="btn btn-calendar" onClick={() => {
                        confirmMoveInventory();
                    }}> Confirmar
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalDetailMovements} style={{width: "40%"}}>
                <div className="card-header d-flex">
                    <div className="text-center" style={{width: '90%'}}>
                        <strong style={{color: '#000'}}>
                            {(dataDetailMovements && dataDetailMovements.storehouse_destination_name !== 'PROCESO') ? 'LOTE ' + dataDetailMovements.batch_code : 'PROCESO DE LOTE ' + dataDetailMovements.batch_code}
                        </strong>
                    </div>
                    <div style={{width: '10%'}} className="text-right">
                        <button type="button" className="btn btn-calendar badge-circle" onClick={() => {
                            setModalDetailMovements(false);
                        }}> X
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="timeline">
                        {(dataDetailMovements && dataDetailMovements.storehouse_destination_name !== 'PROCESO') ?
                            <>
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="ml-1">
                                            <strong>{dataDetailMovements.storehouse_origin_name}</strong><br/>
                                            <span>Peso registrado: {parseFloat(dataDetailMovements.weight_movement).toFixed(2)} gr</span><br/>
                                            <span>Nombre destinatario: {
                                                (dataSelected) && (
                                                    // eslint-disable-next-line
                                                    dataSelected.movimientos.map((x) => {
                                                        if (x.storehouse_destination_name === dataDetailMovements.storehouse_origin_name) {
                                                            return x.recipient_name
                                                        }
                                                    })
                                                )
                                            }</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="ml-1">
                                            <span>{dataDetailMovements.date_create}</span>
                                        </div>
                                    </div>
                                </li>
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="ml-1">
                                            <strong>{dataDetailMovements.storehouse_destination_name}</strong><br/>
                                            <span>Peso registrado: {parseFloat(dataDetailMovements.weight_movement).toFixed(2)} gr</span><br/>
                                            <span>Nombre destinatario: {dataDetailMovements.recipient_name}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="ml-1">
                                            <span>{dataDetailMovements.date_create}</span>
                                        </div>
                                    </div>
                                </li>
                            </>
                            :
                            <>
                                <li className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="ml-1">
                                            <strong>INICIO DE PROCESO</strong><br/>
                                            <span>Peso registrado: {parseFloat(dataDetailMovements.weight_movement).toFixed(2)} gr</span><br/>
                                            <span>Creado : {dataDetailMovements.recipient_name}</span>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <div className="ml-1">
                                            <span>{dataDetailMovements.date_create}</span>
                                        </div>
                                    </div>
                                </li>
                                {(dataDetailMovements.processing_details && dataDetailMovements.processing_details.processing_movements) && (
                                    dataDetailMovements.processing_details.processing_movements.map((_) => (
                                        <li className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div className="ml-1">
                                                    <strong>{_.movement_name}</strong><br/>
                                                    <span>Peso registrado: {parseFloat(_.weight_movement).toFixed(2)} gr</span><br/>
                                                    <span>Joyero: {_.jewelwer_name}</span><br/>
                                                    <span>Creado por: {_.user_create_name}</span><br/>
                                                    {(_.materials.length > 0) && (
                                                        <>
                                                            <strong>Materiales</strong>
                                                            <ul>
                                                                {_.materials.map((x) => (
                                                                    <li>{x.name} {parseFloat(x.weight).toFixed(2)} gr</li>
                                                                ))}
                                                            </ul>

                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <div className="ml-1">
                                                    <span>{_.date_create}</span>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                )}
                            </>
                        }
                    </ul>
                </div>
                <div className="card-footer text-center">
                    {(dataDetailMovements.storehouse_destination_name === 'PROCESO' && (value_state === '' || value_state !== 'LIGAR FIN') && (value_button === 0)) && (
                        <button type="button" className="btn btn-calendar" onClick={() => {
                            setModalProcessBox(true);
                            setModalDetailMovements(false);
                            // setWeight_movement(0);
                            setJeweler_id(0);
                            setDataMaterials([]);
                            if (value_state === '' || value_state === 'REFINAR FIN') {
                                document.getElementById("jeweler_id").selectedIndex = 0;
                            }
                        }}> Siguiente
                        </button>
                    )
                    }
                    {(dataDetailMovements.storehouse_destination_name === 'PROCESO' && value_state === 'LIGAR FIN' && value_button === 1) && (
                        <button type="button" className="btn btn-calendar" onClick={() => {
                            setModalMoveInventoryProcess(true);
                            setRecipient_name_process('');
                            setModalDetailMovements(false);
                        }}> Mover
                        </button>
                    )
                    }
                </div>
            </Modal>
            <Modal className="modal-main" show={modalProcessBox} style={{width: "40%"}}>
                <div className="card-header d-flex">
                    <div className="text-center" style={{width: '90%'}}>
                        <strong style={{
                            background: '#FFC912', paddingTop: 5,
                            paddingBottom: 5, paddingLeft: 15, paddingRight: 15,
                            borderRadius: 30, fontSize: 18, color: '#0D2339'
                        }}
                        >
                            {
                                (value_state === '') ? 'Refinar inicio' :
                                    (value_state === 'REFINAR INICIO') ? 'Refinar fin' :
                                        (value_state === 'REFINAR FIN') ? 'Ligar inicio' : 'Ligar fin'
                            }
                        </strong>
                    </div>
                    <div style={{width: '10%'}} className="text-right">
                        <button type="button" className="btn btn-calendar badge-circle" onClick={() => {
                            setModalProcessBox(false);
                        }}> X
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    {
                        (value_state === '' || value_state === 'REFINAR FIN') ? (
                            <>
                                <h5>Entrega de material</h5>
                                <strong>Peso a entregar</strong><br/>
                                <span>{(weight_movement === 0) ? parseFloat(dataDetailMovements.weight_movement).toFixed(2) : parseFloat(weight_movement).toFixed(2)} gr</span>
                                <br/><br/>
                                <label className="form-label">Joyero recibe material</label>
                                <Select
                                    id="jeweler_id"
                                    label="Joyero"
                                    name="jeweler_id"
                                    options={jeweler.map((item) => ({
                                        name: item.nombres + ' ' + item.apellidos,
                                        id: item.id,
                                    }))}
                                    onChange={(x) => {
                                        setJeweler_id(parseInt(x.target.value))
                                    }}
                                />
                                {(value_state === 'REFINAR FIN') && (
                                    <>
                                        <div className="d-flex">
                                            <div style={{width: '90%'}}>
                                                <h5>Materiales complementarios</h5>
                                            </div>
                                            <div style={{width: '10%'}} className="text-right">
                                                <button type="button" className="btn btn-calendar badge-circle"
                                                        onClick={() => {
                                                            create_div_materials();
                                                        }} style={{fontSize: 25}}> +
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            {dataMaterials.map((item, index) => (
                                                <div className="d-flex">
                                                    <div style={{width: '45%', padding: 10}}>
                                                        <label className="form-label">Material</label>
                                                        <Select
                                                            name="material_id"
                                                            options={materials.map((item) => ({
                                                                name: item.nombre,
                                                                id: item.id,
                                                            }))}
                                                            onChange={(x) => {
                                                                handleChange_material(index, parseInt(x.target.value))
                                                            }}
                                                            value={item.material_id}
                                                            // select={item.material_id}
                                                        />
                                                    </div>
                                                    <div style={{width: '45%', padding: 10}}>
                                                        <Input
                                                            type="number"
                                                            name="weight"
                                                            label="Peso"
                                                            onChange={(x) => {
                                                                handleChange_weight(index, parseFloat(x.target.value))
                                                            }}
                                                            value={item.weight}
                                                        />
                                                    </div>
                                                    <div className="text-center"
                                                         style={{width: '10%', cursor: 'pointer', paddingTop: 25}}
                                                         onClick={() => {
                                                             remove_div_material(index)
                                                         }}>
                                                        <img
                                                            src={window.location.origin + "/base/app-assets/images/icon/delete.png"}
                                                            alt="" height="40" width="40"
                                                            className="mr-1"/>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                                <div className="text-center">
                                    <button type="button" className="btn btn-calendar" onClick={() => {
                                        createMoveProcessInventory();
                                    }}>Entregar material
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <h5>Recepción de material</h5>
                                <strong>Peso entregado</strong><br/>
                                <span>{parseFloat(dataDetailMovements.weight_movement).toFixed(2)} gr</span>
                                <br/>
                                {(value_state === 'LIGAR INICIO' && value_materials.length > 0) && (
                                    <>
                                        <br/>
                                        <strong style={{fontSize: 15}}>Materiales complementarios</strong>
                                        <div className="d-flex">
                                            <div style={{width: '50%', padding: 10}} className="text-center">
                                                <small>Material</small>
                                            </div><div style={{width: '50%', padding: 10}} className="text-center">
                                            <small>Peso</small>
                                        </div>
                                        </div>
                                        {value_materials.map((x) => (
                                            <div className="d-flex">
                                                <div style={{width: '50%', padding: 10, margin: 2}} className="border_material">
                                                    <span>{x.name}</span>
                                                </div>
                                                <div style={{width: '50%', padding: 10, margin: 2}} className="text-right border_material">
                                                    <span>{parseFloat(x.weight).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                                <br/>
                                <Input
                                    type="number"
                                    name="weight_movement"
                                    id="weight_movement"
                                    label="Peso a recibir"
                                    onChange={handleChange_weight_movement}
                                    value={weight_movement}
                                />
                                <div className="text-center">
                                    <button type="button" className="btn btn-calendar" onClick={() => {
                                        createMoveProcessInventory();
                                    }}>Recibir material
                                    </button>
                                </div>
                            </>
                        )
                    }
                </div>
            </Modal>
            <Modal className="modal-main" show={modalMoveInventoryProcess} style={{width: "25%"}}>
                <div className="card-body">
                    <div className="text-center mb-3">
                        <img
                            src={window.location.origin + "/base/app-assets/images/icon/ayuda.png"}
                            alt="" height="100" width="100" className="mb-5"/>
                        <h5>Se registrará el siguiente movimiento de inventario:</h5>
                    </div>
                    {(dataDetailMovements && dataDetailMovements.processing_details && dataDetailMovements.processing_details.final_weight) &&
                        <div>
                            <div className="d-flex">
                                <div className="mr-2">
                                    <img
                                        src={window.location.origin + "/base/app-assets/images/icon/entra.png"}
                                        alt="" height="50" width="50" className="mb-5"/>
                                </div>
                                <div>
                                    <span>Desde:</span> <strong>PROCESO</strong><br/>
                                    <span>Peso:</span> <strong>{parseFloat(dataDetailMovements.processing_details.starting_weight).toFixed(2)} gr</strong>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="mr-2">
                                    <img
                                        src={window.location.origin + "/base/app-assets/images/icon/sale.png"}
                                        alt="" height="50" width="50" className="mb-5"/>
                                </div>
                                <div>
                                    <span>Hacia:</span> <strong>CAJA 1</strong><br/>
                                    <span>Peso:</span> <strong>{parseFloat(dataDetailMovements.processing_details.final_weight).toFixed(2)} gr</strong>
                                </div>
                            </div>
                        </div>
                    }
                    <Input
                        type="text"
                        name="recipient_name_process"
                        label="Entregado a"
                        onChange={(x) => {
                            setRecipient_name_process(x.target.value)
                        }}
                        value={recipient_name_process}
                    />
                </div>
                <div className="card-footer text-center">
                    <button type="button" className="btn btn-calendar mr-2" onClick={() => {
                        setModalMoveInventoryProcess(false);
                    }}> Cancelar
                    </button>
                    <button type="button" className="btn btn-calendar" onClick={() => {
                        confirmMoveInventoryProcess();
                    }}> Confirmar
                    </button>
                </div>
            </Modal>
            <Modal className="modal-main" show={modalChronology} style={{width: "40%"}}>
                <div className="card-header d-flex">
                    <div className="text-center" style={{width: '90%'}}>
                        <strong style={{
                           fontSize: 18, color: '#0D2339'
                        }}
                        >
                            Cronología lote {dataSelected.codigo}
                        </strong>
                    </div>
                    <div style={{width: '10%'}} className="text-right">
                        <button type="button" className="btn btn-calendar badge-circle" onClick={() => {
                            setModalChronology(false);
                        }}> X
                        </button>
                    </div>
                </div>
                <div className="card-body">
                    <ul className="timeline">
                        <li className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="ml-1">
                                    <strong>Creación</strong><br/>
                                    <span>Peso registrado: {parseFloat(dataSelected.peso_disponible).toFixed(2)} gr</span><br/>
                                    <span>Registrado por: {dataSelected.registrado}</span>
                                </div>
                            </div>
                            <div className="text-right">
                                <div className="ml-1">
                                    <span>{dataSelected.fecha}</span>
                                </div>
                            </div>
                        </li>
                        {dataSelected.movimientos.map((x) => (
                            <li className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <div className="ml-1">
                                        <strong>Movimiento</strong><br/>
                                        <span>Peso registrado: {parseFloat(x.weight_movement).toFixed(2)} gr</span><br/>
                                        <span>Desde: {x.storehouse_origin_name}</span><br/>
                                        <span>Hacia: {x.storehouse_destination_name}</span><br/>
                                        <span>Recibido por: {x.recipient_name}</span><br/>
                                        <span>Registrado por: {x.user_create}</span>
                                    </div>
                                </div>
                                <div className="text-right">
                                    <div className="ml-1">
                                        <span>{x.date_create}</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </Modal>
        </div>
    );
};

export default Inventory;
