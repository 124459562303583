import {LOGOUT} from './users'
import axios from "axios";
import {REPORT_CHECK_PATH, URLAPI} from "../config";

const init = {
    _get_notification: {
        data: [],
    },
}

export const GET_NOTIFICATION = "GET_NOTIFICATION";

export const notification_module = (state = init, action) => {
    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                _get_notification: action.payload,
            };

        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const check_export_status = async (token, task_id) => {
    return await axios.get(`${URLAPI}${REPORT_CHECK_PATH}${task_id}/`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    })
}


export const save_notification = (data) => async (dispatch) => {

    dispatch({
        type: GET_NOTIFICATION,
        payload: {
            data: data,
        }
    });
}
