import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_jeweler,
    clear_status_jeweler,
    CREATE_JEWELER_SUCCESS,
    get_all_jeweler
} from "../../../redux/jeweler";
import Footer from "../../../components/footer";

const CreateJeweler = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {_create_jeweler} = useSelector((state) => state.jeweler_module);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [form, setForm] = useState({
        identificacion: "",
        nombres: "",
        apellidos: "",
    });

    useEffect(() => {
        if (_create_jeweler.status === 201) {
            toast.success("El joyero ha sido creado correctamente");
            dispatch(clear_status_jeweler(CREATE_JEWELER_SUCCESS));
            history.push("/joyeros");
            dispatch(get_all_jeweler(token))
        } else if (_create_jeweler.status !== 0) {
            if (_create_jeweler.message) {
                toast.error(_create_jeweler.message);
                dispatch(clear_status_jeweler(CREATE_JEWELER_SUCCESS));
            }
        }
    }, [_create_jeweler, history, dispatch, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.identificacion === "") {
            toast.error("Debe ingresar la identificación");
            return;
        }
        if (form.nombres === "") {
            toast.error("Debe ingresar los nombres");
            return;
        }
        if (form.apellidos === "") {
            toast.error("Debe ingresar los apellidos");
            return;
        }
        dispatch(create_jeweler(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Joyero"
                            items={[
                                {label: "Joyeros", link: "/joyeros"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Joyero</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="identificacion"
                                                name="identificacion"
                                                label="Identificación"
                                                value={form.identificacion}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                id="nombres"
                                                name="nombres"
                                                label="Nombres"
                                                value={form.nombres}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                id="apellidos"
                                                name="apellidos"
                                                label="Apellidos"
                                                value={form.apellidos}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/joyeros" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-calendar"
                                        onClick={() => {
                                        }}
                                        text="Crear"
                                        theme="calendar"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateJeweler;
