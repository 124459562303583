import axios from "axios";
import {
    URLAPI,
    MOVEMENT_CREATE_PATH,
    MOVEMENT_DETAILS_PATH,
    MOVEMENT_CREATE_PROCESSING_PATH,
} from "../config";
import {LOGOUT} from './users';

const init = {
    _create_movement: {
        data: {},
        status: 0,
        message: {},
    },
    _create_movement_processing: {
        data: {},
        status: 0,
        message: {},
    },
}

export const CREATE_MOVEMETS_SUCCESS = "CREATE_MOVEMETS_SUCCESS";
export const CREATE_MOVEMETS_PROCESSING_SUCCESS = "CREATE_MOVEMETS_PROCESSING_SUCCESS";

export const movement_module = (state = init, action) => {
    switch (action.type) {
        case CREATE_MOVEMETS_SUCCESS:
            return {
                ...state,
                _create_movement: action.payload,
            };
        case CREATE_MOVEMETS_PROCESSING_SUCCESS:
            return {
                ...state,
                _create_movement_processing: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const create_movements = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${MOVEMENT_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_MOVEMETS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_MOVEMETS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_MOVEMETS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}

export const create_movement_processing = (token, data) => async (dispatch) => {
    try {
        const response = await axios.post(`${URLAPI}${MOVEMENT_CREATE_PROCESSING_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_MOVEMETS_PROCESSING_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_MOVEMETS_PROCESSING_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_MOVEMETS_PROCESSING_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear",
                },
            });
        }
    }
}


export const detailMovements = async (token, id) => {
    return await axios.get(`${URLAPI}${MOVEMENT_DETAILS_PATH}${id}/`, {
        headers: {
            Authorization: `Basic ${token}`,
        },
    });
}

export const clear_status_movement = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
