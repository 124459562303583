import React, {useEffect, useState, useContext} from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import route from "./routes";
import {useSelector, useDispatch} from "react-redux";
import {get_all_users, get_all_groups, get_all_permisions} from '../redux/users';
import {get_all_zones} from '../redux/zone';
import {get_all_societies} from '../redux/society';
import {get_all_clients} from '../redux/client';
import {get_all_materials} from '../redux/material';
import {get_all_karatages} from '../redux/karatages';
import {clear_status_batche, get_all_batches, GET_REPORT} from '../redux/batches_inventory';
import {check_export_status} from "../redux/notifications";
import {get_all_batches_manufacturing} from '../redux/batches_manufacturing';
import {get_all_store_houses} from '../redux/store_houses';
import {get_all_products} from '../redux/product';
import {get_all_jeweler} from '../redux/jeweler';
import {SocketContext} from "../config/ws-client";
import {
    TYPE_UPDATE_MESSAGE,
    TYPE_REPORT_GENERATE,
    TYPE_CREATE_MESSAGE,
} from '../config/ws-client'
import {toast} from "react-toastify";
import {save_notification} from "../redux/notifications";


const Index = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const _data_login = useSelector((state) => state.users_module.login);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [permisos, setPermissions] = useState([]);
    const {socket} = useContext(SocketContext);
    const _get_notification = useSelector((state) => state.notification_module._get_notification);
    const [token, setToken] = useState("")

    const audio = new Audio("/base/assets/audio/audio.mp3");

    // var day = new Date().getDate();
    // var month = new Date().getMonth() + 1;
    // var year = new Date().getFullYear();
    // if (day < 10) {
    //     day = '0' + day;
    // }
    // if (month < 10) {
    //     month = '0' + month;
    // }
    // var date = year + '-' + month + '-' + day;

    useEffect(() => {
        if (_data_login.status >= 200) {
            dispatch(get_all_users(_data_login.data.token));
            dispatch(get_all_groups(_data_login.data.token));
            dispatch(get_all_permisions(_data_login.data.token));
            dispatch(get_all_zones(_data_login.data.token));
            dispatch(get_all_societies(_data_login.data.token));
            dispatch(get_all_clients(_data_login.data.token));
            dispatch(get_all_materials(_data_login.data.token));
            dispatch(get_all_karatages(_data_login.data.token));
            dispatch(get_all_batches(_data_login.data.token));
            dispatch(get_all_batches_manufacturing(_data_login.data.token));
            dispatch(get_all_store_houses(_data_login.data.token));
            dispatch(get_all_products(_data_login.data.token));
            dispatch(get_all_jeweler(_data_login.data.token));
            setToken(_data_login.data.token)
        } else {
            history.push("/");
        }

        if (JSON.stringify(_session_data.data) !== "{}") {
            if (_session_data.data.grupo_permiso) {
                if (_session_data.data.grupo_permiso.permisos) {
                    setPermissions(_session_data.data.grupo_permiso.permisos);
                }
            }
        }

    }, [dispatch, _data_login, _session_data, history]);


    useEffect(() => {
        handleMessageWs();
        // eslint-disable-next-line
    }, [socket])

    // const sleep = (ms) => {
    //     return new Promise(resolve => setTimeout(resolve, ms));
    // }


    const handleMessageWs = () => {
        if (socket) {
            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === TYPE_UPDATE_MESSAGE) {
                    update_message(data, socket)
                    return;
                }

                if (data.type === TYPE_REPORT_GENERATE) {
                    report_generate(data, socket);
                }

                if (data.type === TYPE_CREATE_MESSAGE) {
                    create_message(data, socket)
                }

            }
        }
    }


    const update_message = (data, socket) => {
        toast.success(data.message);

        const notification = {
            title: data.title,
            message: data.message,
            result: null,
            id: data.id,
            read: false,
            type: TYPE_UPDATE_MESSAGE,
            show: true,
            date: data.date
        }
        let n = _get_notification.data ?? [];
        n.push(notification)
        dispatch(save_notification(n));
        dispatch(get_all_batches(token));
        audio.play();
    }

    const create_message = (data, socket) => {
        dispatch(get_all_batches(token));
    }

    let timer = null;

    const report_generate = (data, socket) => {
        timer = setInterval(() => {
            check_export_status(token, data.id_task).then(e => {
                let datos = e.data;
                if (e.status === 200) {
                    dispatch(clear_status_batche(GET_REPORT))
                    const report = {
                        title: "Reporte Generado ",
                        message: datos.result.nombre,
                        result: datos.result,
                        id: datos.id_task,
                        read: false,
                        date: datos.date,
                        type: TYPE_REPORT_GENERATE,
                        show: true

                    }
                    let n = _get_notification.data ?? [];
                    n.push(report)
                    dispatch(save_notification(n));

                    audio.play();
                    clearInterval(timer)
                    timer = null;

                }
            }).catch(e => {
                clearInterval(timer)
                timer = null;
            })
        }, 5000)
    }

    return (
        <Router>
            <div className="header-navbar-shadow"/>
            <Navbar/>
            <Sidebar/>
            <Switch>
                {/* eslint-disable-next-line*/}
                {route.map((item, index) => {
                    if (!item.meta.requiresAuth) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }

                    if (permisos.filter(x => item.meta.permission_required.includes(x.codigo)).length > 0) {
                        return (
                            <Route path={item.path} exact={item.exact}>
                                {<item.component {...permisos} />}
                            </Route>
                        );
                    }
                })}
            </Switch>
        </Router>
    );
};
export default Index;
