export const URLAPI = "https://pruebas.back.lefar.innode.pro";
export const WEBSOCKET = 'wss://pruebas.back.lefar.innode.pro';

export const ZONE_PATH = "/clients/zones-list/";
export const ZONE_CREATE_PATH = "/clients/zone-create/";
export const ZONE_UPDATE_PATH = "/clients/zone-update/";

export const SOCIETY_PATH = "/clients/societies-list/";
export const SOCIETY_CREATE_PATH = "/clients/society-create/";
export const SOCIETY_UPDATE_PATH = "/clients/society-update/";

export const CLIENT_PATH = "/clients/clients-list/";
export const CLIENT_CREATE_PATH = "/clients/client-create/";
export const CLIENT_UPDATE_PATH = "/clients/client-update/";
export const PRODUCT_PATH = "/manufacturing/product-list/";
export const PRODUCT_CREATE_PATH = "/manufacturing/product-create/";
export const PRODUCT_UPDATE_PATH = "/manufacturing/product-update/";
export const JEWELER_PATH = "/manufacturing/jeweler-list/";
export const JEWELER_CREATE_PATH = "/manufacturing/jeweler-create/";
export const JEWELER_UPDATE_PATH = "/manufacturing/jeweler-update/";
export const MATERIAL_PATH = "/inventory/materials-list/";
export const MATERIAL_CREATE_PATH = "/inventory/materials-create/";
export const MATERIAL_UPDATE_PATH = "/inventory/materials-update/";
export const KARATAGES_PATH = "/inventory/karatages-list/";
export const STORE_HOUSES_PATH = "/inventory/store-houses-list/";
export const BATCHE_PATH = "/inventory/batchs-list/";
export const DETAIL_BATCHE_PATH = "/inventory/details/batch/";
export const BATCHES_REPORT_PATH = "/inventory/report/";
export const REPORT_CHECK_PATH = "/base/report/check/";
export const SEARCH_STOREHOUSE_BATCHES_PATH = "/inventory/storehouse-for-batch/";
export const BATCHE_CREATE_PATH = "/inventory/batch-create/";
export const MOVEMENT_CREATE_PATH = "/inventory/register/movement_inventory/";
export const MOVEMENT_DETAILS_PATH = "/inventory/details/movement/";
export const MOVEMENT_CREATE_PROCESSING_PATH = "/inventory/create/movement-processing/";
export const TOKEN_PATH = "/user/token/";
export const USERS_PATH = "/user/list/";
export const USERS_UPDATE_PATH = "/user/update/";
export const USERS_CREATE_PATH = "/user/create/";
export const USER_GROUPS_PATH = "/user/group-list/";
export const USER_GET_PATH = "/user/get/";
export const USER_GET_ALL_PERMISSIONS_PATH = '/user/permission-list/';
export const USER_CREATE_GROUP_PATH = '/user/group-create/';
export const USER_UPDATE_GROUP_PATH = '/user/group-update/';
export const USER_ACTIVATE_PATH = '/user/activate/';
export const USER_LOGOUT_PATH = '/user/logout/';
export const USER_PASSWORD_RESET_PATH = '/user/password/reset/';
export const USER_PASSWORD_RESET_CONFIRM_PATH = '/user/password/reset/confirm/';
export const BATCHE_MANUFACTURING_PATH = "/manufacturing/batches-list/";
export const BATCHE_MANUFACTURING_CREATE_PATH = "/manufacturing/batches-create/";
export const APP_NAME = "Sistema Lextecs";


