import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import SocietyList from '../pages/admin/society/index';
import CreateSociety from '../pages/admin/society/create';
import UpdateSociety from '../pages/admin/society/update';
import ZoneList from '../pages/admin/zone/index';
import CreateZone from '../pages/admin/zone/create';
import UpdateZone from '../pages/admin/zone/update';
import ClientList from '../pages/admin/client/index';
import CreateClient from '../pages/admin/client/create';
import UpdateClient from '../pages/admin/client/update';
import MaterialList from '../pages/admin/material/index';
import CreateMaterial from '../pages/admin/material/create';
import UpdateMaterial from '../pages/admin/material/update';
import InventoryList from '../pages/admin/inventory/index';
// import ManufacturingList from '../pages/admin/manufacturing/index';
// import ProductList from '../pages/admin/product/index';
// import CreateProduct from '../pages/admin/product/create';
// import UpdateProduct from '../pages/admin/product/update';
import JewelerList from '../pages/admin/jeweler/index';
import CreateJeweler from '../pages/admin/jeweler/create';
import UpdateJeweler from '../pages/admin/jeweler/update';

const routes = [
    {
        path: "/",
        name: "inventatario",
        icon: "bx bxs-component",
        exact: true,
        component: InventoryList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Inventatario",
            permission_required: [
                "view_batches",
            ],
        },
    },
    // {
    //     path: "/manufactura",
    //     name: "manufactura",
    //     icon: "bx bxs-package",
    //     exact: true,
    //     component: ManufacturingList,
    //     show_in_menu: true,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Manufactura",
    //         permission_required: [
    //             "view_product",
    //         ],
    //     },
    // },
    {
        path: "/material",
        name: "material",
        icon: "bx bx-list-plus",
        exact: true,
        component: MaterialList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Material",
            permission_required: [
                "view_material",
            ],
        },
    },
    {
        path: "/material/crear",
        name: "material",
        icon: "bx bx-list-plus",
        exact: true,
        component: CreateMaterial,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Material",
            permission_required: [
                "add_material",
            ],
        },
    },
    {
        path: "/material/editar/:id",
        name: "material",
        icon: "bx bx-list-plus",
        exact: true,
        component: UpdateMaterial,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Material",
            permission_required: [
                "change_material",
            ],
        },
    },
    {
        path: "/sociedades",
        name: "sociedades",
        icon: "bx bxs-city",
        exact: true,
        component: SocietyList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Sociedades",
            permission_required: [
                "view_society",
            ],
        },
    },
    {
        path: "/sociedad/crear",
        name: "sociedades",
        icon: "bx bxs-city",
        exact: true,
        component: CreateSociety,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Sociedades",
            permission_required: [
                "add_society",
            ],
        },
    },
    {
        path: "/sociedad/editar/:id",
        name: "sociedades",
        icon: "bx bxs-city",
        exact: true,
        component: UpdateSociety,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Sociedades",
            permission_required: [
                "change_society",
            ],
        },
    },
    {
        path: "/zonas",
        name: "zonas",
        icon: "bx bx-map-pin",
        exact: true,
        component: ZoneList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "view_zone",
            ],
        },
    },
    {
        path: "/zona/crear",
        name: "zonas",
        icon: "bx bx-map-pin",
        exact: true,
        component: CreateZone,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "add_zone",
            ],
        },
    },
    {
        path: "/zona/editar/:id",
        name: "zonas",
        icon: "bx bx-map-pin",
        exact: true,
        component: UpdateZone,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "change_zone",
            ],
        },
    },
    {
        path: "/clientes",
        name: "clientes",
        icon: "bx bxs-store",
        exact: true,
        component: ClientList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Clientes",
            permission_required: [
                "view_client",
            ],
        },
    },
    {
        path: "/cliente/crear",
        name: "clientes",
        icon: "bx bxs-store",
        exact: true,
        component: CreateClient,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Clientes",
            permission_required: [
                "add_client",
            ],
        },
    },
    {
        path: "/cliente/editar/:id",
        name: "clientes",
        icon: "bx bxs-store",
        exact: true,
        component: UpdateClient,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Clientes",
            permission_required: [
                "change_client",
            ],
        },
    },
    // {
    //     path: "/productos",
    //     name: "producto",
    //     icon: "bx bxs-box",
    //     exact: true,
    //     component: ProductList,
    //     show_in_menu: true,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Producto",
    //         permission_required: [
    //             "view_product",
    //         ],
    //     },
    // },
    // {
    //     path: "/producto/crear",
    //     name: "producto",
    //     icon: "bx bxs-box",
    //     exact: true,
    //     component: CreateProduct,
    //     show_in_menu: false,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Producto",
    //         permission_required: [
    //             "add_product",
    //         ],
    //     },
    // },
    // {
    //     path: "/producto/editar/:id",
    //     name: "producto",
    //     icon: "bx bxs-box",
    //     exact: true,
    //     component: UpdateProduct,
    //     show_in_menu: false,
    //     meta: {
    //         requiresAuth: true,
    //         title: "Producto",
    //         permission_required: [
    //             "change_product",
    //         ],
    //     },
    // },
    {
        path: "/joyeros",
        name: "joyero",
        icon: "bx bx-diamond",
        exact: true,
        component: JewelerList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Joyeros",
            permission_required: [
                "view_jeweler",
            ],
        },
    },
    {
        path: "/joyero/crear",
        name: "joyero",
        icon: "bx bx-diamond",
        exact: true,
        component: CreateJeweler,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Joyero",
            permission_required: [
                "add_jeweler",
            ],
        },
    },
    {
        path: "/joyero/editar/:id",
        name: "joyero",
        icon: "bx bx-diamond",
        exact: true,
        component: UpdateJeweler,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Joyero",
            permission_required: [
                "change_jeweler",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    }
];

export default routes;
