import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {
    update_materials,
    clear_status_material,
    get_all_materials,
    UPDATE_MATERIALS_SUCCESS,
} from "../../../redux/material";
import {Switch, Space} from "antd";
import Footer from "../../../components/footer";

const UpdateMaterial = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {id} = useParams();
    const {_update_materials, _get_all_materials} = useSelector((state) => state.material_module);
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    useEffect(() => {
        if (_get_all_materials) {
            if (_get_all_materials.status === 200) {
                if (JSON.stringify(_get_all_materials.data) !== "{}") {
                    // eslint-disable-next-line
                    _get_all_materials.data.datos.map((item) => {
                        if (item.id === parseInt(id)) {
                            setForm(item);
                        }
                    });
                }
            }
        }
    }, [_get_all_materials, id]);

    useEffect(() => {
        if (_update_materials) {
            if (_update_materials.status === 200) {
                toast.success("El material ha sido actualizada correctamente");
                dispatch(clear_status_material(UPDATE_MATERIALS_SUCCESS));
                history.push("/material");
                dispatch(get_all_materials(token))
            } else if (_update_materials.status !== 0) {
                if (_update_materials.message) {
                    toast.error(_update_materials.message);
                    dispatch(clear_status_material(UPDATE_MATERIALS_SUCCESS));
                }
            }
        }
    }, [_update_materials, dispatch, history, token]);

    const [form, setForm] = useState({
        nombre: "",
        activo: "",
    });
    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //validate fields
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            return;
        }
        dispatch(update_materials(token, id, form));
    };

    const handleCheckedChange = (checked) => {
        setForm({
            ...form,
            activo: checked,
        });
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Material"
                            items={[
                                {label: "Material", link: "/material"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Material</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="nombre"
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6 mt-1">
                                            <Space size={14} direction='horizontal'>
                                                <strong>Activo</strong>
                                                <Switch
                                                    checked={form.activo}
                                                    checkedChildren="SI"
                                                    unCheckedChildren="NO"
                                                    onChange={handleCheckedChange}
                                                />
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/material" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-calendar"
                                        onClick={() => {
                                        }}
                                        text="Guardar"
                                        theme="calendar"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UpdateMaterial;
