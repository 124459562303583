import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {
    Link,
    useHistory
} from "react-router-dom";
import Footer from "../../../components/footer";

const Materials = (props) => {
    const history = useHistory();
    const materials = useSelector(
        (state) => state.material_module._get_all_materials
    );
    const _data_login = useSelector((state) => state.users_module.login.data);
    const [data, setData] = useState([]);
    // eslint-disable-next-line
    const [login, setLogin] = useState({});
    const [permisos, setPermisos] = useState([]);
    useEffect(() => {
        if (_data_login) {
            setLogin(_data_login);
        }
        if (materials.data.datos) {
            setData(materials.data.datos);
        }
    }, [materials, _data_login]);


    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    const [actions, setActions] = useState([]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_material').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        history.push({pathname: `/material/editar/${item.id}`, state: {data: item}});
                    },
                })
            }


        }
        setActions(act);


    }, [permisos, history]);

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Material"
                            items={[{label: "Material", link: "/material"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_material').length > 0 ? <Link
                                        to="/material/crear"
                                        className="btn btn-calendar"
                                    >
                                        Crear
                                    </Link> : null
                                }

                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "nombre",
                                                label: "Nombre",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha Creacion",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Materials;
