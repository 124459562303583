import axios from "axios";
import {
    URLAPI,
    CLIENT_PATH,
    CLIENT_CREATE_PATH,
    CLIENT_UPDATE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_clients: {
        data: [],
        status: 0,
        message: {},
    },
    _create_clients: {
        data: {},
        status: 0,
        message: {},
    },
    _update_clients: {
        data: {},
        status: 0,
        message: {},
    },
}

const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const CREATE_CLIENTS_SUCCESS = "CREATE_CLIENTS_SUCCESS";
export const UPDATE_CLIENTS_SUCCESS = "UPDATE_CLIENTS_SUCCESS";

export const client_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                _get_all_clients: action.payload,
            };
        case CREATE_CLIENTS_SUCCESS:
            return {
                ...state,
                _create_clients: action.payload,
            };
        case UPDATE_CLIENTS_SUCCESS:
            return {
                ...state,
                _update_clients: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_clients = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${CLIENT_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_CLIENTS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_clients = (token, data) => async (dispatch) => {
    try {
        console.log(data);

        const response = await axios.post(`${URLAPI}${CLIENT_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_CLIENTS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear la tienda",
                },
            });
        }
    }
}

export const update_clients = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${CLIENT_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_CLIENTS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_CLIENTS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const clear_status_client = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
