import React, {useEffect, useState} from "react";
import {TYPE_REPORT_GENERATE} from "../config/ws-client";
import {logout} from '../redux/users';
import {useDispatch, useSelector} from "react-redux";
import {URLAPI} from '../config/index'
import {save_notification} from '../redux/notifications';


const Navbar = (props) => {
    const dispatch = useDispatch();
    const [notification, setNotification] = useState([]);
    const _get_notification = useSelector((state) => state.notification_module._get_notification);
    const _session_data = useSelector((state) => state.users_module._session_data);
    const [open, setOpen] = useState(false);
    const [user_data, setUserData] = useState({
        usuario: "",
        nombres: "",
        apellidos: "",
        correo: "",
        foto: "",
        grupo_permiso: {
            nombre: ""
        }
    });

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    useEffect(() => {
        if (_get_notification.data) {
            setNotification(_get_notification.data);
        }
    }, [_get_notification.data]);

    useEffect(() => {
        if (_session_data && _session_data.data) {
            setUserData(_session_data.data);
        }
    }, [_session_data]);


    useEffect(() => {
        if (open) {
            openMenu();
        } else {
            hideMenu();
        }
    }, [open])


    const handleClick = () => {
        dispatch(logout(token));
    }

    const download = (url, nombre) => {
        if (url && nombre) {
            var element = document.createElement('a');
            element.target = '_BLANK';
            document.body.appendChild(element);
            element.style = "display: none";

            element.href = url
            element.download = nombre

            element.click();
            document.body.removeChild(element)
        }

    }

    const openMenu = () => {
        document.body.classList.remove('menu-hide', 'menu-collapsed')
        document.body.classList.add('menu-open')
        document.body.classList.add('menu-expanded')

        if (document.body.classList.contains('vertical-overlay-menu')) {

            document.body.style = "overflow:hidden;"
        }
    }

    const hideMenu = () => {
        document.body.classList.remove('menu-open', 'menu-expanded')
        document.body.classList.add('menu-hide')

        document.body.style = "overflow:auto;"

    }

    const handleClickNotification = (data) => {

        if (data.type === TYPE_REPORT_GENERATE) {
            if (data.result) {
                download(URLAPI + data.result.url, data.result.nombre);
            }
        }

        let filter = notification
        let final = []
        filter.forEach(x => {
            let d = x;
            if (data.id === x.id) {
                if (d.read === false) {
                    d.read = true;
                }
            }
            final.push(d);
        })
        dispatch(save_notification(final));
        return;

    }


    const handleClickDropNotification = () => {
        let filter = notification
        let final = []
        filter.forEach(x => {
            let data = x;
            if (data.show === true) {
                data.show = false;
            }
            final.push(data);
        });
        dispatch(save_notification(final));
        setNotification(final)

    }

    return (
        <>
            <div className="header-navbar-shadow"/>
            <nav className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top ">
                <div className="navbar-wrapper">
                    <div className="navbar-container content">
                        <div className="navbar-collapse" id="navbar-mobile">
                            <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                                <ul className="nav navbar-nav">
                                    <li className="nav-item mobile-menu d-xl-none mr-auto"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {/*eslint-disable-next-line*/}
                                        <a
                                            className="nav-link nav-menu-main menu-toggle hidden-xs"
                                            href="#"
                                            onClick={() => setOpen(!open)}
                                        >
                                            <i className="ficon bx bx-menu"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className="nav navbar-nav float-right">
                                {/*eslint-disable-next-line*/}

                                <li className="dropdown dropdown-notification nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="nav-link nav-link-label"
                                        href="#"
                                        data-toggle="dropdown"
                                        onClick={handleClickDropNotification}
                                    >
                                        <i className="ficon bx bxs-download bx-tada bx-flip-horizontal"></i>
                                        {notification.filter(x => x.show === true).length > 0 && (
                                            <span className="badge badge-pill badge-danger badge-up">
                                                {notification.filter(x => x.show === true).length}
                                            </span>
                                        )}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                        <li className="dropdown-menu-header">
                                            <div className="dropdown-header px-1 py-75 d-flex justify-content-between">
                                                <span className="notification-title">
                                                    {notification.length} Notificaciones
                                                </span>
                                            </div>
                                        </li>
                                        <li className="scrollable-container media-list read-notification cursor-pointer"
                                            style={{overflow: 'scroll', '-webkit-overflow-scrolling': 'touch'}}>
                                            {/*eslint-disable-next-line*/}
                                            {notification.length != 0 ? notification.reverse().map((notification, index) => (
                                                // eslint-disable-next-line
                                                <a className={`d-flex justify-content-between ${notification.read == true ? '' : 'read-notification'}`}
                                                   href="#" onClick={() => handleClickNotification(notification)}>
                                                    <div className="media d-flex align-items-center">
                                                        <div className="media-left pr-0">
                                                            <div className="avatar mr-1 m-0">
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <h6 className="media-heading">
                                                                <span className="text-bold-500">{notification.title}</span>
                                                                {notification.message}
                                                            </h6>
                                                            <small className="notification-text">
                                                                {notification.date}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </a>
                                            )) : null}
                                        </li>
                                    </ul>
                                </li>
                                <li className="dropdown dropdown-user nav-item">
                                    {/*eslint-disable-next-line*/}
                                    <a
                                        className="dropdown-toggle nav-link dropdown-user-link"
                                        href="#"
                                        data-toggle="dropdown"
                                    >
                                        <div className="user-nav d-sm-flex d-none">
                                            <span className="user-name">{user_data.nombres + " " + user_data.apellidos}</span>
                                            <span
                                                className="user-status text-muted">{user_data.grupo_permiso !== undefined ? user_data.grupo_permiso.nombre : ""}</span>
                                        </div>
                                        <span>
                                            <img
                                                className="round"
                                                src={user_data.foto ? URLAPI + "/media/" + user_data.foto : window.location.origin + "/base/app-assets/images/icon/user.png"}
                                                alt="avatar"
                                                height="35"
                                                width="35"
                                            />
                                        </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right pb-0">

                                        <div className="dropdown-divider mb-0"/>
                                        {/*eslint-disable-next-line*/}
                                        <a className="dropdown-item" href="#" onClick={handleClick}>
                                            <i className="bx bx-power-off mr-50"/> Logout
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
