import axios from "axios";
import {
    URLAPI,
    MATERIAL_PATH,
    MATERIAL_CREATE_PATH,
    MATERIAL_UPDATE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_materials: {
        data: [],
        status: 0,
        message: {},
    },
    _create_materials: {
        data: {},
        status: 0,
        message: {},
    },
    _update_materials: {
        data: {},
        status: 0,
        message: {},
    },
}

const GET_ALL_MATERIALS_SUCCESS = "GET_ALL_MATERIALS_SUCCESS";
export const CREATE_MATERIALS_SUCCESS = "CREATE_MATERIALS_SUCCESS";
export const UPDATE_MATERIALS_SUCCESS = "UPDATE_MATERIALS_SUCCESS";

export const material_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_MATERIALS_SUCCESS:
            return {
                ...state,
                _get_all_materials: action.payload,
            };
        case CREATE_MATERIALS_SUCCESS:
            return {
                ...state,
                _create_materials: action.payload,
            };
        case UPDATE_MATERIALS_SUCCESS:
            return {
                ...state,
                _update_materials: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_materials = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${MATERIAL_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_MATERIALS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_materials = (token, data) => async (dispatch) => {
    try {
        console.log(data);

        const response = await axios.post(`${URLAPI}${MATERIAL_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_MATERIALS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el material",
                },
            });
        }
    }
}

export const update_materials = (token, id, data) => async (dispatch) => {
    try {
        const response = await axios.patch(`${URLAPI}${MATERIAL_UPDATE_PATH}${id}/`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: UPDATE_MATERIALS_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response.data != null) {
            dispatch({
                type: UPDATE_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_MATERIALS_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
}

export const clear_status_material = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
