import axios from "axios";
import {
    URLAPI,
    BATCHE_MANUFACTURING_PATH,
    BATCHE_MANUFACTURING_CREATE_PATH,
} from "../config";
import {LOGOUT} from './users'

const init = {
    _get_all_batches_manufacturing: {
        data: [],
        status: 0,
        message: {},
    },
    _create_batches_manufacturing: {
        data: {},
        status: 0,
        message: {},
    },
    // _update_batches: {
    //     data: {},
    //     status: 0,
    //     message: {},
    // },
}

const GET_ALL_BATCHES_MANUFACTURING_SUCCESS = "GET_ALL_BATCHES_MANUFACTURING_SUCCESS";
export const CREATE_BATCHES_MANUFACTURING_SUCCESS = "CREATE_BATCHES_MANUFACTURING_SUCCESS";
// export const UPDATE_BATCHES_SUCCESS = "UPDATE_BATCHES_SUCCESS";

export const batche_manufacturing_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_BATCHES_MANUFACTURING_SUCCESS:
            return {
                ...state,
                _get_all_batches_manufacturing: action.payload,
            };
        case CREATE_BATCHES_MANUFACTURING_SUCCESS:
            return {
                ...state,
                _create_batches_manufacturing: action.payload,
            };
        // case UPDATE_BATCHES_SUCCESS:
        //     return {
        //         ...state,
        //         _update_batches: action.payload,
        //     };
        case LOGOUT:
            return init;
        default:
            return state;
    }
}

export const get_all_batches_manufacturing = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${BATCHE_MANUFACTURING_PATH}`, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_BATCHES_MANUFACTURING_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: GET_ALL_BATCHES_MANUFACTURING_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.message,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_BATCHES_MANUFACTURING_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Ocurrió un error",
                },
            });
        }
    }
}

export const create_batches_manufacturing = (token, data) => async (dispatch) => {
    try {
        console.log(data);

        const response = await axios.post(`${URLAPI}${BATCHE_MANUFACTURING_CREATE_PATH}`, data, {
            headers: {
                Authorization: `Basic ${token}`,
            },
        });
        dispatch({
            type: CREATE_BATCHES_MANUFACTURING_SUCCESS,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response != null) {
            dispatch({
                type: CREATE_BATCHES_MANUFACTURING_SUCCESS,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_BATCHES_MANUFACTURING_SUCCESS,
                payload: {
                    data: {},
                    status: 500,
                    message: "Error al crear el lote",
                },
            });
        }
    }
}

// export const searchStoreHouseBatches = async (token, id) => {
//     return await axios.get(`${URLAPI}${SEARCH_STOREHOUSE_BATCHES_PATH}${id}/`, {
//         headers: {
//             Authorization: `Basic ${token}`,
//         },
//     });
// }

// export const detailBatches = async (token, id) => {
//     return await axios.get(`${URLAPI}${DETAIL_BATCHE_PATH}${id}/`, {
//         headers: {
//             Authorization: `Basic ${token}`,
//         },
//     });
// }

// export const update_batches = (token, id, data) => async (dispatch) => {
//     try {
//         const response = await axios.patch(`${URLAPI}${BATCHE_UPDATE_PATH}${id}/`, data, {
//             headers: {
//                 Authorization: `Basic ${token}`,
//             },
//         });
//         dispatch({
//             type: UPDATE_BATCHES_SUCCESS,
//             payload: {
//                 data: response.data,
//                 status: response.status,
//                 message: "",
//             },
//         });
//     } catch (error) {
//         if (error.response.data != null) {
//             dispatch({
//                 type: UPDATE_BATCHES_SUCCESS,
//                 payload: {
//                     data: {},
//                     status: error.response.status,
//                     message: error.response.data.mensaje,
//                 },
//             });
//         } else {
//             dispatch({
//                 type: UPDATE_BATCHES_SUCCESS,
//                 payload: {
//                     data: {},
//                     status: 500,
//                     message: error.message,
//                 },
//             });
//         }
//     }
// }

export const clear_status_batche_manufacturing = (type) => async dispatch => {
    dispatch({
        type: type,
        payload: {
            data: {},
            status: 0,
            message: {},
        },
    });
}
