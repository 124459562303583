import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_materials,
    clear_status_material,
    CREATE_MATERIALS_SUCCESS,
    get_all_materials
} from "../../../redux/material";
import Footer from "../../../components/footer";

const CreateMaterial = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {_create_materials} = useSelector((state) => state.material_module);
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [form, setForm] = useState({
        nombre: "",
    });

    useEffect(() => {
        if (_create_materials.status === 201) {
            toast.success("El material ha sido creada correctamente");
            dispatch(clear_status_material(CREATE_MATERIALS_SUCCESS));
            history.push("/material");
            dispatch(get_all_materials(token))
        } else if (_create_materials.status !== 0) {
            if (_create_materials.message) {
                toast.error(_create_materials.message);
                dispatch(clear_status_material(CREATE_MATERIALS_SUCCESS));
            }
        }
    }, [_create_materials, history, dispatch, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            return;
        }
        dispatch(create_materials(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Material"
                            items={[
                                {label: "Material", link: "/material"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Material</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="nombre"
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/material" className="btn btn-outline-dark m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-calendar"
                                        onClick={() => {
                                        }}
                                        text="Crear"
                                        theme="calendar"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default CreateMaterial;
