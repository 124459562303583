import {createStore, combineReducers, compose, applyMiddleware} from "redux";
import {loadState} from "./state";
import thunk from "redux-thunk";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {users_module} from "./users";
import {zone_module} from "./zone";
import {society_module} from "./society";
import {client_module} from "./client";
import {material_module} from "./material";
import {karatage_module} from "./karatages";
import {batche_module} from "./batches_inventory";
import {batche_manufacturing_module} from "./batches_manufacturing";
import {store_house_module} from "./store_houses";
import {product_module} from "./product";
import {movement_module} from "./movements_inventory";
import {jeweler_module} from "./jeweler";
import {notification_module} from "./notifications";

const rootReducer = combineReducers({
    users_module: users_module,
    zone_module: zone_module,
    society_module: society_module,
    client_module: client_module,
    material_module: material_module,
    karatage_module: karatage_module,
    batche_module: batche_module,
    batche_manufacturing_module: batche_manufacturing_module,
    store_house_module: store_house_module,
    product_module: product_module,
    movement_module: movement_module,
    jeweler_module: jeweler_module,
    notification_module: notification_module,
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const generateStore = () => {
    const initialData = loadState();

    const store = createStore(
        persistedReducer,
        initialData,
        composeEnhancers(applyMiddleware(thunk))
    );

    const persistor = persistStore(store, ["auth"]);

    return {store, persistor};
};
